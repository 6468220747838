var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{
    'mt-3 mb-1': _vm.$vuetify.breakpoint.smAndDown,
    'mt-10 mb-6': _vm.$vuetify.breakpoint.mdAndUp,
  }},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 desc"},[_c('base-subheading',[_vm._v(_vm._s(_vm.$t("pages_specific.main_page.about.title")))]),_c('div',{class:{
          'mt-5 mb-0': _vm.$vuetify.breakpoint.smAndDown,
          'mt-5 mb-7': _vm.$vuetify.breakpoint.mdAndUp,
        }},[_vm._l((_vm.$t('pages_specific.main_page.about.p')),function(item,i){return _c('p',{key:i},[_vm._v(" "+_vm._s(item)+" ")])}),_c('ul',_vm._l((_vm.$t('pages_specific.main_page.about.list_of_study_services')),function(item,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(item)+" ")])}),0)],2)],1),_c('div',{staticClass:"col-lg-6 text-center"},[_c('img',{class:{
          'limit-height-14': _vm.$vuetify.breakpoint.smOnly,
          'limit-height-10': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"src":require("@/assets/images/box_cropped.png"),"alt":""}})])]),_c('v-btn',{staticClass:"text-none font-large accent",class:{
      'mt-5 mb-3': _vm.$vuetify.breakpoint.smAndDown,
    },attrs:{"large":"","color":"dark","text":"","to":"/company/contact"}},[_vm._v(" "+_vm._s(_vm.$t("global.pages_titles.contact"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }